import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import styled from "styled-components"
import SvgFixed from "../svg-fixed"
import LogoBranco from "../../assets/svg/logo-branco.svg"
import SocialContainer from "../social-container"

const CustomFooter = styled.footer`
	background-color: var(--secondary);
	color: var(--white);
	padding: 100px 0;
	position: relative;
	z-index: 3;
`

const CustomContainer = styled(Container)`
	max-width: 1640px;
`
const DivContato = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;

	@media (max-width: 996px) {
		text-align: center;
	}

	span {
		font-size: 24px;
		font-weight: 500;

		@media (max-width: 996px) {
			font-size: 18px;
		}
	}

	a {
		font-size: 42px;
		font-weight: 900;
		color: var(--white);
		text-decoration: none;
		margin-top: -10px;

		@media (max-width: 996px) {
			font-size: 32px;
		}
	}
`

const DivLogo = styled.div`
	width: fit-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: auto;

	@media (max-width: 996px) {
		padding-top: 80px;
		margin: auto;
	}

	span {
		font-size: 12px;
	}

	a {
		font-size: 12px;
		color: var(--white);
		text-decoration: none;
	}
`

const Footer = () => {
	return (
		<CustomFooter>
			<CustomContainer id="contato">
				<Row>
					<Col lg="6">
						<DivContato>
							<span>Fale conosco</span>
							<a href="tel:+5517991564129">17 99156-4129</a>
							<SocialContainer />
						</DivContato>
					</Col>
					<Col lg="6">
						<DivLogo>
							<SvgFixed
								id={LogoBranco.id}
								width="293px"
								height="104px"
							/>
							<span>
								© TecSolar 2020, desenvolvido por{" "}
								<a
									href="https://agencia3r.com.br"
									target="agencia3r"
								>
									3r Agência
								</a>
							</span>
						</DivLogo>
					</Col>
				</Row>
			</CustomContainer>
		</CustomFooter>
	)
}

export default Footer
