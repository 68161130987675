import React, { Component } from "react"

import styled from "styled-components"
import { Nav } from "react-bootstrap"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Link } from "react-scroll"
import SlideDownAnimation from "../../../animations/slide-down"

const ToggleMenu = styled.div`
	display: none;
	@media (max-width: ${props => props.collapseWidth}) {
		display: inline;
	}
`
const MenuSpan = styled.span`
	width: 27px;
	height: ${props => (props.show ? "0" : "2.25px")};
	background: var(--white);
	display: block;
	position: relative;
	z-index: 3;

	&:before {
		width: 27px;
		height: 2.25px;
		background: var(--white);
		content: "";
		left: 0;
		bottom: ${props => (props.show ? "0" : "7px")};
		position: absolute;
		transform: ${props => (props.show ? "rotate(45deg)" : "rotate(0)")};
		transition: ${props =>
			props.show
				? "bottom 0.1s, transform 0.1s 0.3s ease-in"
				: "transform 0.1s, bottom 0.1s 0.3s ease-in"};
	}
	&:after {
		width: 27px;
		height: 2.25px;
		background: var(--white);
		content: "";
		left: 0;
		bottom: ${props => (props.show ? "0" : "-7px")};
		position: absolute;
		transform: ${props => (props.show ? "rotate(-45deg)" : "rotate(0)")};
		transition: ${props =>
			props.show
				? "bottom 0.1s, transform 0.1s 0.3s ease-in"
				: "transform 0.1s, bottom 0.1s 0.3s ease-in"};
	}
`

const ContentMenu = styled.div`
	padding: 1rem;

	@media not all and (max-width: ${props => props.collapseWidth}) {
		.navbar-nav {
			flex-direction: row;
		}
		.nav-link {
			padding-right: 0.5rem;
			padding-left: 0.5rem;
		}
	}
	@media (max-width: ${props => props.collapseWidth}) {
		background: var(--primary);
		position: fixed;
		bottom: 100%;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		transition: transform 1s cubic-bezier(0.49, 0.01, 0.27, 1),
			visibility 0s;
		transform: translateY(${props => (props.show ? 0 : "-100%")});
		z-index: 2;

		display: flex;
		align-items: center;
		justify-content: center;
	}
`

class NavbarMenuHeader extends Component {
	constructor(props) {
		// super( props ), é necessário pra utilizar o props do component, do qual ele é extendido
		super(props)

		// atribui valor inicial
		this.state = {
			show: false,
		}
	}

	handlerToggleMenu = () => {
		this.setState({ show: !this.state.show })
		if (this.state.show) {
			// FECHA MENU
			document.body.style.overflow = "auto"
		} else {
			// ABRE MENU
			document.body.style.overflow = "hidden"
		}
	}

	linkScrollClick = to => {
		if (this.state.show) {
			this.handlerToggleMenu()
			setTimeout(() => scrollTo(to), 100)
		} else {
			scrollTo(to)
		}
	}

	render() {
		// caso não venha nada do props o valor inicial de collapse é 768px
		const { collapseWidth = "1200px" } = this.props

		return (
			<>
				{/* fragment  = <> */}
				<ToggleMenu
					onClick={this.handlerToggleMenu}
					collapseWidth={collapseWidth}
				>
					<MenuSpan show={this.state.show}></MenuSpan>
					<div className="sr-only">Menu</div>
				</ToggleMenu>
				<ContentMenu
					collapseWidth={collapseWidth}
					show={this.state.show}
				>
					<Nav>
						<ul>
							<li {...SlideDownAnimation} data-sal-delay="200">
								<Link
									onClick={() =>
										this.linkScrollClick("#vantagens")
									}
									to=""
								>
									Vantagens
								</Link>
							</li>
							<li {...SlideDownAnimation} data-sal-delay="300">
								<Link
									onClick={() =>
										this.linkScrollClick("#comoFunciona")
									}
									to=""
								>
									Como Funciona
								</Link>
							</li>
							<li {...SlideDownAnimation} data-sal-delay="100">
								<Link
									onClick={() =>
										this.linkScrollClick("#cotacao")
									}
									to=""
								>
									{" "}
									Fazer Cotação
								</Link>
							</li>
							<li {...SlideDownAnimation} data-sal-delay="400">
								<Link
									onClick={() =>
										this.linkScrollClick("#sobreNos")
									}
									to=""
								>
									Quem Somos
								</Link>
							</li>
							<li {...SlideDownAnimation} data-sal-delay="500">
								<Link
									onClick={() =>
										this.linkScrollClick("#contato")
									}
									to=""
								>
									Contato
								</Link>
							</li>
						</ul>
					</Nav>
				</ContentMenu>
			</>
		)
	}
}

export default NavbarMenuHeader
