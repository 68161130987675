import PropTypes from "prop-types"
import React, { Component } from "react"
import { Container, Navbar } from "react-bootstrap"
import { Link } from "react-scroll"
import scrollTo from "gatsby-plugin-smoothscroll"
import LogoSvgHeader from "./logo-svg-header"
import NavbarMenuHeader from "./navbar-menu-header"
import styled from "styled-components"
import SlideDownAnimation from "../../../animations/slide-down"

const CustomNavbar = styled(Navbar)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2;
	transition: 0.3s ease-in-out;

	ul {
		color: var(--white);
		display: flex;
		justify-content: space-between;
		width: 50vw;
		margin: 0;

		@media (max-width: 1200px) {
			flex-direction: column;
			width: 100%;
			height: 50vh;
			padding: 0;
		}
	}

	li {
		list-style: none;
		font-size: 16px;
		font-weight: 900;
		text-transform: uppercase;
		cursor: pointer;

		@media (max-width: 1200px) {
			font-size: 20px;
		}
	}

	h1 {
		cursor: pointer;
	}
`

const CustomContainer = styled(Container)`
	max-width: 1640px;
`

class Header extends Component {
	constructor(props) {
		super(props)

		this.state = {
			show: true,
			color: false,
		}

		this.lastScrollTop = 0
		this.lastScrollTopDescendo = 0
	}

	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll, { passive: true })
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll)
	}

	handleScroll = event => {
		var scrollTop = window.pageYOffset || document.documentElement.scrollTop

		if (scrollTop < 50) {
			// sempre visível
			this.setState({ show: true, color: false })
		} else if (scrollTop > this.lastScrollTop) {
			//descendo
			this.setState({ show: false })
			this.lastScrollTopDescendo = scrollTop
		} else {
			//subindo
			if (scrollTop < this.lastScrollTopDescendo - 100) {
				this.setState({ show: true, color: true })
			}
		}

		this.lastScrollTop = scrollTop
	}

	render() {
		const { siteTitle } = this.props
		return (
			<header>
				<CustomNavbar
					expand="lg"
					style={{
						top: this.state.show ? "0" : "-100px",
						background: this.state.color
							? "rgba(40, 59, 89, 0.8)"
							: "transparent",
					}}
					{...SlideDownAnimation}
				>
					<CustomContainer>
						<Navbar.Brand className="mr-auto">
							<h1 className="mb-0">
								<Link
									onClick={() => scrollTo("#inicio")}
									to=""
									className="d-block"
								>
									<LogoSvgHeader siteTitle={siteTitle} />
									<span className={"sr-only"}>
										{siteTitle}
									</span>
								</Link>
							</h1>
						</Navbar.Brand>
						<NavbarMenuHeader collapseWidth="1200px" />
					</CustomContainer>
				</CustomNavbar>
			</header>
		)
	}
}

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header
