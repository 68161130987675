import React, { Component } from "react"
import PropTypes from "prop-types"

import Header from "./header/header"
import Footer from "./footer"
import Wpp from "../wpp-button"
import { loadReCaptcha } from "react-recaptcha-v3"

class Layout extends Component {
	componentDidMount() {
		loadReCaptcha("6Le9mbcZAAAAAEnVgQByb9HlS4LPTSCXxLrYDXxB", null)
	}

	render() {
		const { children, hasHeader = true } = this.props

		return (
			<>
				{hasHeader ? <Header siteTitle={"Tecsolar"} /> : ""}
				<main>
					<div data-scroll>{children}</div>
					<Wpp />
				</main>
				<Footer />
			</>
		)
	}
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
