import React from "react"

import Instagram from "../assets/svg/instagram.svg"
import Facebook from "../assets/svg/facebook.svg"
import SvgFixed from "./svg-fixed"
import styled from "styled-components"

const CustomDiv = styled.div`
	svg {
		margin-right: 30px;
	}

	@media (max-width: 996px) {
		margin: auto;

		svg {
			margin: 12px 15px 0 15px;
		}
	}
`

const SocialContainer = () => (
	<CustomDiv>
		<a
			href="https://www.facebook.com/tecsolar.eng/"
			target="_blank"
			rel="noopener noreferrer"
		>
			<SvgFixed
				className="mr-30px"
				id={Facebook.id}
				width="24px"
				height="24px"
				mdWidth="36px"
				mdHeight="36px"
			/>
		</a>
		<a
			href="https://www.instagram.com/tecsolareng/"
			target="_blank"
			rel="noopener noreferrer"
		>
			<SvgFixed
				id={Instagram.id}
				width="24px"
				height="24px"
				mdWidth="36px"
				mdHeight="36px"
			/>
		</a>
	</CustomDiv>
)

export default SocialContainer
