import React from "react"

import styled from "styled-components"

import SvgFixed from "../components/svg-fixed"
import WppSvg from "../assets/svg/whatsapp.svg"
import SlideUpAnimation from "../animations/slide-up"

const Botao = styled.div`
	display: flex;
	position: fixed;
	bottom: 5%;
	right: 5%;
	background-color: var(--secondary);
	padding: 20px;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	z-index: 1;
	a {
		text-decoration: none;
	}
	svg {
		fill: var(--${props => props.fillColor});
	}

	@media (max-width: 1500px) {
		padding: 15px;
	}

	@media (max-width: 990px) {
		padding: 13px;
	}
`

const Wpp = () => {
	return (
		<a
			href="https://api.whatsapp.com/send?phone=5517991564129"
			target="_blank"
			rel="noopener noreferrer"
		>
			<Botao fillColor={"white"} {...SlideUpAnimation}>
				<SvgFixed
					id={WppSvg.id}
					desc={`Logo marca`}
					width="24px"
					height="24px"
					mdWidth="31px"
					mdHeight="31px"
					lgWidth="40px"
					lgHeight="40px"
				/>
			</Botao>
		</a>
	)
}

export default Wpp
