import React from "react"
import logoSvg from "../../../assets/svg/logo-branco.svg"
import SvgFixed from "../../svg-fixed"

const LogoSvgHeader = ({ siteTitle }) => (
	<SvgFixed
		id={logoSvg.id}
		desc={`Logo:  ${siteTitle}`}
		width="139px"
		height="49px"
		mdWidth="200px"
		mdHeight="71px"
	/>
)

export default LogoSvgHeader
